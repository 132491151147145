import { WbButton, WbHeading, WbText } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GettingStartedVideo from './GettingStartedVideo';

function GettingStartedIntro() {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const onGettingStartedButtonClick = () => {
		window.open(window.REACT_VIMDT_BASE_URL, '_blank');
	};

	const onDemoButtonClick = () => {
		navigate('/demo');
	};

	return (
		<div className="gs-intro">
			<WbHeading className="gs-intro__title" size="l">
				{t('gettingStarted.intro')}
			</WbHeading>
			<GettingStartedVideo className="gs-intro__video" />
			<WbText className="gs-intro__text" size="l">
				{t('gettingStarted.text')}
			</WbText>
			<WbButton className="gs-intro__start-button" onClick={onGettingStartedButtonClick} variant="primary">
				{t('gettingStarted.gettingStartedButton')}
			</WbButton>
			<div className="gs-intro__title">
				<WbText size="l" strong>
					{t('gettingStarted.exampleTextBold')}
				</WbText>
				<WbText size="l">{t('gettingStarted.exampleText')}</WbText>
			</div>
			<WbButton className="gs-example-actions__explore-button" onClick={onDemoButtonClick} variant="secondary">
				{t('gettingStarted.exampleButton')}
			</WbButton>
			<WbButton
				className="gs-example-actions__video-playlist-button"
				variant="secondary"
				onClick={() => window.open('video-playlist', '_blank', 'noopener,noreferrer')}
			>
				{t('gettingStarted.videoPlaylistButton')}
			</WbButton>
		</div>
	);
}

export default GettingStartedIntro;
