import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WbGridCol, WbText } from '@workbench/react';
import EquipmentDetail from '../../Demo/DemoTabContents/Equipment/EquipmentDetail';
import '../../Demo/DemoTabContents/DemoTabContents.scss';
import { RdCardButtonBar, RdCard, RdCardDetails } from '../../Common/RDCard/RDCard';

function NoDataContent({ message }) {
    return (
        <WbGridCol mq1={12}>
            <WbText>{message}</WbText>
        </WbGridCol>
    );
}

function SimpleTabContent({ tab, detail, equipmentImages, className }) {
    const { t } = useTranslation();

    if (tab.type === "basic_equipments") {
        return (
            <WbGridCol mq1={12} mq3={6} mq4={6} mq5={4} key={detail.key} className={className}>
                <RdCard img={equipmentImages.EQUIPMENTS[detail.key]?.A24?.urls} title={detail.value} />
            </WbGridCol>
        );

    }
    return (
        <WbGridCol mq5={4} mq4={4} mq1={12} key={detail.key} className={className}>
            <WbText size="m" className="rd-demo-content-simple__text">
                {t(`demoPage.${detail.key}`)}
            </WbText>
            <WbText size="l" strong>
                {detail.value}
            </WbText>
        </WbGridCol>
    );

}

function MeServicesTabContent({ mmesArray, equipmentHighlightFilter }) {
    const { i18n } = useTranslation();
    const [mmesData, setMmesData] = useState(mmesArray);

    useEffect(() => {
        const fillMmesData = async () => {
            setMmesData(mmesArray);
        }
        fillMmesData();

    }, [i18n.language]);

    return mmesData.map((detail, index) => (
        <WbGridCol key={index} mq1={12} mq3={6} mq4={6} mq5={4} className="rd-demo-content__element">
            <RdCard
                icon={detail.serviceIconImage}
                title={detail.heading}
                rightTag={detail.subHeading}
                img={detail.primaryImage}
            >
                <RdCardDetails icon={detail.serviceIconImage} title={detail.heading}>
                    <WbText size="s">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: detail.summary }} />
                    </WbText>
                </RdCardDetails>
                <RdCardButtonBar showMoreDetailsButton equipmentHighlightFilter={equipmentHighlightFilter} />
            </RdCard>
        </WbGridCol>
    ));
}

function EquipmentTabContent({ detail, vehicleData, steveArray, equipmentImages, className, equipmentHighlightFilter }) {
    const { t, i18n } = useTranslation();
    const { key: code, value: title } = detail;
    const adjustedSteveArray = steveArray?.map((item) => {
        const newArray = { ...item, ...item?.entity };
        delete newArray.entity;
        return newArray;
    });
    const [steveData, setSteveData] = useState(adjustedSteveArray?.find((item) => item.code === code));

    useEffect(() => {
        const fillSteveData = async () => {
            setSteveData(adjustedSteveArray?.find((item) => item.code === code));
        }
        fillSteveData();

    }, [i18n.language]);

    const hasDetails = steveData?.components?.components.length > 0;

    const imgUrls = equipmentImages?.EQUIPMENTS[code]?.A24.urls;
    const hasHighlight = steveData?.highlight && t('demoTabs.highlight');
    const hasMercedesMe = steveData?.mercedesMe && t('demoTabs.mercedesMe');
    const cardTitle = `${t(title)} (${code})`;
    const salesDesignationValue = steveData?.salesDesignation;
    const functionTextValue = steveData?.functionText || '';
    const cardDetailTitle = `${salesDesignationValue} ${functionTextValue}`;
    return (
        <WbGridCol mq1={12} mq3={6} mq4={6} mq5={4} key={code} className={className}>
            <RdCard equipmentHighlightFilter={equipmentHighlightFilter} img={imgUrls} title={cardTitle} rightTag={hasHighlight} leftTag={hasMercedesMe}>
                {steveData && (
                    <RdCardDetails title={cardDetailTitle}>
                        <EquipmentDetail steveData={steveData} />
                    </RdCardDetails>
                )}
                {steveArray && <RdCardButtonBar showMoreDetailsButton={hasDetails} equipmentHighlightFilter={equipmentHighlightFilter} />}
            </RdCard>
        </WbGridCol>
    );
}

function TabContents({ children, tab, vehicleData, mmesData, steveData, equipmentImages, className, equipmentHighlightFilter }) {
    const { t } = useTranslation();
    if (tab.details.length === 0) return <NoDataContent message={tab.name === 'meServices' ? t('demoTabs.content.noDataAvailableForMmes') : t('demoTabs.content.noDataAvailable')} />;
    if (tab.name === 'meServices') return <MeServicesTabContent vehicleData={vehicleData} mmesArray={mmesData} equipmentHighlightFilter={equipmentHighlightFilter} />;

    if (equipmentImages) {
        const tabDetailsWithImage = [...tab.details];

        const tabDetailsWithoutImage = tab.details.filter((detail) => {
            const noImage = !equipmentImages?.EQUIPMENTS[detail.key]?.A24?.urls;
            if (noImage) {
                tabDetailsWithImage.splice(tabDetailsWithImage.indexOf(detail), 1);
            }
            return noImage;
        });

        return [...tabDetailsWithImage, ...tabDetailsWithoutImage].map((detail) =>
            React.Children.map(children, (child) => React.cloneElement(child, { tab, detail, vehicleData, steveArray: steveData, equipmentImages, className, equipmentHighlightFilter }))
        );
    }

    return tab.details.map((detail) =>
        React.Children.map(children, (child) => React.cloneElement(child, { tab, detail, vehicleData, steveArray: steveData, equipmentImages, className, equipmentHighlightFilter })))


}

const chooseTabContent = (type) => {
    switch (type) {
        case 'equipment':
            return <EquipmentTabContent />;
        default:
            return <SimpleTabContent />;
    }
};

export { TabContents, chooseTabContent };
