/* eslint-disable react/no-array-index-key */
import {
    WbButton,
    WbCard,
    WbGrid,
    WbGridCol,
    WbGridRow,
    WbHeading,
    WbInput,
    WbSelect,
    WbSelectionContext,
    WbSelectionContextItem,
    WbSpinner,
    WbStep,
    WbStepper,
    WbText,
    WbToggle
} from '@workbench/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import marketList from '../../../Constants/marketList';
import { getMmesData, getV2SData, getV2SEquipments, getVehicleEquipmentImages, getVehicleImage, getVehicleImages, getVehicleVideo } from '../../../Utils/apiCalls';
import convertImage from '../../../Utils/convertImage';
import createNotification from '../../../Utils/notification';
import './VinInput.scss';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function VinInput() {

    const { t } = useTranslation();

    const [market, setMarket] = useState("de_DE");

    const [vin, setVin] = useState(null);

    const [oneKeyForAll, setOneKeyForAll] = useState(true);
    const [loadFromFile, setLoadFromFile] = useState(false);

    const [vimApiKey, setVimApiKey] = useState(null);
    const [vimEquipmentsApiKey, setVimEquipmentsApiKey] = useState(null);
    const [vvsApiKey, setVvsApiKey] = useState(null);
    const [v2sApiKey, setV2sApiKey] = useState(null);

    const [showSaveButton, setShowSaveButton] = useState(null);
    const [dataToSave, setDataToSave] = useState(null);

    const mq5Start = 4;
    const mq5Columns = 4;

    const [loadingSteps, setLoadingSteps] = useState([
        { name: 'Images', icon: 'bds/camera/24', status: '', variant: '', errors: [] },
        { name: 'Specification', icon: 'bds/document/24', status: '', variant: '', errors: [] },
        { name: 'Equipments', icon: 'bds/wheel/24', status: '', variant: '', errors: [] },
        { name: 'Video', icon: 'bds/youtube/24', status: '', variant: '', errors: [] }
    ]);

    const [vehicleDetailsId, setVehicleDetailsId] = useState(null);


    const [loading, setLoading] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    const [loadStatus, setLoadStatus] = useState("");

    const equipmentTabs = [
        {
            name: 'exterior',
            details: [],
            type: 'equipment',
        },
        {
            name: 'interior',
            details: [],
            type: 'equipment',
        },
        {
            name: 'infotainment',
            details: [],
            type: 'equipment',
        },
        {
            name: 'safety',
            details: [],
            type: 'equipment',
        },
        {
            name: 'technicalDetails',
            details: [],
            type: 'equipment',
        }
    ];
    const tabLayout = [
        {
            name: 'generalData',
            details: [],
        },
        {
            name: 'packages',
            details: [],
            type: 'equipment',
        },
        ...equipmentTabs
    ];

    const demoVinData = {
        "name": null,
        "exteriorImage": null,
        "interiorImage": null,
        "images": [],
        "interiorImages": [],
        "bisImages": [],
        "trunkImage": null,
        "disableNight": true,
        "night": {
            "exteriorImage": null,
            "interiorImage": null,
            "images": [],
            "interiorImages": [],
            "bisImages": [],
            "trunkImage": null
        },
        "videoDetails": null,
        "tabs": [...tabLayout]
    }


    const handleLoadError = (e) => ({
        isError: true,
        message: (e?.response?.data?.errorMessage || e.message),
        statusCode: e?.response?.status
    });

    const loadImagesData = async (params) => {
        const vimData = await getVehicleImages(params).then(resp => resp.data).catch(e => handleLoadError(e));
        return vimData;
    }

    const loadImages = async (params, loadedFromFileImagesData) => {
        let vimData = null;
        if(loadedFromFileImagesData){
            vimData = loadedFromFileImagesData;
        }else{
            vimData = await loadImagesData(params);
        }
        if (vimData?.isError) {
            return vimData;
        }

        const images = {};

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        const imagePromises = [];

        for (const perspective in vimData) {
            // Get image if url is not empty
            if (vimData[perspective] !== "" && vimData[perspective] !== null) {
                // add perspective to images with empty string to keep order
                images[perspective] = "";
                params.imageUrl = vimData[perspective];
                const imagePromise = getVehicleImage(params)
                    .then(resp => resp.data)
                    .then(imageData => {
                    const { url } = convertImage(imageData);
                    images[perspective] = url;
                    })
                    .catch(() => {
                        // remove perspective from images if image could not be loaded
                        delete images[perspective];
                     });
                imagePromises.push(imagePromise);
            }
        }

        await Promise.all(imagePromises);
        return { images, vimData };
    }

    const loadMmesData = async (params) => {
        const mmesData = await getMmesData(params).then(resp => resp.data).catch(e => handleLoadError(e));
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        if (!mmesData?.isError) {
            return mmesData.products.map(mme => {
                const mmeObj = {};
                mmeObj.heading = mme.name;
                mmeObj.subHeading = mme.categories[0].name;
                mmeObj.summary = mme.summary;
                mmeObj.primaryImage = mme.productTeaserImage;
                mmeObj.serviceIconImage = mme.imageIcon;

                return mmeObj;
            }
            );
        }
        return mmesData;

    }

    const loadSteveData = async (params) => {
        const steveData = await getV2SEquipments(params).then(resp => resp.data).catch(e => handleLoadError(e));
        return steveData;
    }

    const applySteveData = (steveData) => {
        if (steveData && !steveData.isError) {
            steveData.forEach(e => {
                const category = e.categoryPathEnglish.split("/")[1];

                if (category.startsWith('Exterior')) {
                    demoVinData.tabs[2].details.push({ key: e.code, value: e.entity.salesDesignation });
                }
                else if (category.startsWith('Interior')) {
                    demoVinData.tabs[3].details.push({ key: e.code, value: e.entity.salesDesignation });
                }
                else if (category.startsWith('Infotainment') || category.startsWith('Mercedes')) {
                    demoVinData.tabs[4].details.push({ key: e.code, value: e.entity.salesDesignation });
                }
                else if (category.startsWith('Safety')) {
                    demoVinData.tabs[5].details.push({ key: e.code, value: e.entity.salesDesignation });
                }
                else if (category.startsWith('Technology')) {
                    demoVinData.tabs[6].details.push({ key: e.code, value: e.entity.salesDesignation });
                }
            });
        }
        else {
            demoVinData.tabs = [demoVinData.tabs[0], demoVinData.tabs[1]];
        }
    }

    const loadV2SData = async (params) => {
        const v2sData = await getV2SData(params).then(resp => resp.data).catch(e => handleLoadError(e));
        return v2sData;
    }

    const applyV2SData = (v2sData) => {
        if (v2sData && !v2sData.isError) {
            v2sData.vehicleData.options.forEach(e => {
                if (e.codeType === 'PC') {
                    demoVinData.tabs[1].details.push({ key: e.code, value: e.description });
                }
            });

            return v2sData.vehicleData;
        }
        return v2sData;
    }

    const loadVideoData = async (params) => {
        const videoData = await getVehicleVideo(params).then(resp => resp.data).catch(e => handleLoadError(e));
        return videoData;
    }

    const validateBISImages = data => {
        if (!data.BIS1 || !data.BIS2 || !data.BIS3 || !data.BIS4 || !data.BIS5 || !data.BIS6) {
            return false;
        }
        return true;
    }

    const convertToDemoVin = (dayData, nightData) => {
        if ((!dayData || Object.entries(dayData).length < 10) || (!nightData || Object.entries(nightData).length < 10) || (Object.entries(dayData).filter(e => e[0].includes("EXT")).length < 6 || Object.entries(nightData).filter(e => e[0].includes("EXT")).length < 6)) {
            createNotification(t('vinDemoPage.notifications.missingData'), true);
            return false;
        }

        demoVinData.name = vin;
        demoVinData.exteriorImage = dayData.EXT000;
        demoVinData.interiorImage = dayData.INT1;
        demoVinData.images = Object.entries(dayData).filter(e => e[0].includes("EXT")).map(e => e[1]);
        demoVinData.interiorImages = Object.entries(dayData).filter(e => e[0].includes("INT")).map(e => e[1]);
        demoVinData.bisImages = validateBISImages(dayData) ? [dayData.BIS4, dayData.BIS2, dayData.BIS5, dayData.BIS6, dayData.BIS1, dayData.BIS3] : null;
        demoVinData.trunkImage = dayData.BET1;

        if(nightData){
            const demoNightData = {}
            demoNightData.exteriorImage = nightData.EXT000;
            demoNightData.interiorImage = nightData.INT1;
            demoNightData.images = Object.entries(nightData).filter(e => e[0].includes("EXT")).map(e => e[1]);
            demoNightData.interiorImages = Object.entries(nightData).filter(e => e[0].includes("INT")).map(e => e[1]);
            demoNightData.bisImages = validateBISImages(nightData) ? [nightData.BIS4, nightData.BIS2, nightData.BIS5, nightData.BIS6, nightData.BIS1, nightData.BIS3] : null;
            demoNightData.trunkImage = nightData.BET1;
            demoVinData.night = demoNightData;
        }
        return true;
    }

    const createEquipmentsTabFromV2S = (v2sData) => {
        const equipmentsTab = {
            name: 'equipments',
            details: [],
            type: 'basic_equipments',
        };

        v2sData.options.forEach(e => {
            if (e.codeType !== 'PC') {
                equipmentsTab.details.push({ key: e.code, value: e.description });
            }
        });

        return equipmentsTab;
    }

    const setLoadingStatus = (stepName, status, variant = '', errors = []) => {
        switch (stepName.toLowerCase()) {
            case 'images':
                setLoadingSteps(e => { e[0].status = status; e[0].variant = variant; e[0].errors = [...e[0].errors, ...errors]; return e; })
                break;
            case 'specification':
                setLoadingSteps(e => { e[1].status = status; e[1].variant = variant; e[1].errors = [...e[1].errors, ...errors]; return e; })
                break;
            case 'equipments':
                setLoadingSteps(e => { e[2].status = status; e[2].variant = variant; e[2].errors = [...e[2].errors, ...errors]; return e; })
                break;
            case 'video':
                setLoadingSteps(e => { e[3].status = status; e[3].variant = variant; e[3].errors = [...e[3].errors, ...errors]; return e; })
                break;
            default:
                break;
        }

    };

    const resetLoadingStatus = () => {
        const steps = [...loadingSteps];
        setLoadingSteps(steps.map(e => { e.status = ''; e.variant = ''; e.errors = []; return e; }));
    }


    class ApiReturnData{
        constructor(){
            this.v2sData = null;
            this.equipentsData = null;
            this.vvidData = null;
            this.mmesData = null;
            this.steveData = null;
            this.equipmentImages = null;
            this.imagesDayData = null;
            this.vin = null;
        }
    }
    
    
    const handleLoadButton = async (loadedFromFileData) => {
        
        var saveData = new ApiReturnData();
        let vinNr = loadedFromFileData?.vin || vin;
        setShowSaveButton(loadedFromFileData ? false : true);
        

        saveData.vin = vinNr;

        console.log('Loading VIN: ' + vinNr);

        if (loading) {
            createNotification(t('vinDemoPage.notifications.ongoingRequest'), true, 10000);
            return;
        }
        if(!loadedFromFileData){
            if (!vinNr || !vimApiKey || (!oneKeyForAll && !v2sApiKey)) {
                createNotification(t('vinDemoPage.notifications.missingInputs'), true, "30000");
                return;
            }
            if (vinNr.length !== 17) {
                createNotification(t('vinDemoPage.notifications.invalidVin'), true, "30000");
                return;
            }
        }
        setLoadStatus(t('vinDemoPage.notifications.loadingStarted'));
        resetLoadingStatus();
        setVehicleDetailsId(null);
        setShowStatus(true);
        setLoading(true); 
        const vimParams = { vin: vinNr, apikey: vimApiKey, queries: { night: false } };
        const loadedImageData = await loadImages(vimParams, loadedFromFileData?.imagesDayData);
        saveData.imagesDayData = loadedImageData.vimData;
        const dayData = loadedImageData.images;
        // Temporary disabled night images as per MBDEVMAPI-126
        //vimParams.queries.night = true;
        //const nightData = await loadImages(vimParams);
        const nightData = dayData; // TODO: Remove this line when night images are enabled

        if (dayData?.isError || nightData?.isError) {
            setLoadStatus(t('vinDemoPage.userMessage.status.couldntLoadImage'));
            setLoadingStatus('images', '', 'error', [dayData || nightData]);
            setLoading(false);
        }
        else {
            const noError = convertToDemoVin(dayData, nightData);

            if (noError) {
                demoVinData.name = vinNr;
                setLoadingStatus('images', 'done');
                let v2sData = null;
                if(loadedFromFileData){
                    v2sData = loadedFromFileData.v2sData;
                }else{
                    v2sData = await loadV2SData({ vin: vinNr, apikey: (oneKeyForAll ? vimApiKey : v2sApiKey), queries: { marketId: market } });
                    saveData.v2sData = v2sData;
                }
                v2sData = applyV2SData(v2sData);

                if(!v2sData){
                    setLoadStatus(t('vinDemoPage.userMessage.status.couldntLoadV2sData'));
                    setLoading(false);
                    return;
                }
                if (v2sData?.isError) {
                    setLoadStatus(t('vinDemoPage.userMessage.status.couldntLoadV2sData'));
                    setLoadingStatus('specification', '', 'error', [v2sData]);
                    setLoading(false);
                    return;
                }
                // If no error on VIM & V2S, then load other data

                const speedData = v2sData.technicalData?.find(d => d.id === '46T');
                const vehicleCard = [
                    { type: 'heading', value: v2sData.longType },
                    { type: 'car', value: v2sData?.body?.text },
                    { type: 'engine', value: `${v2sData.powerkw} kW (${v2sData.powerps} PS)` },
                    { type: 'mile', value: `${speedData?.value || ''} ${speedData?.unit || ''}` },
                    { type: 'fuel', value: v2sData?.fuel?.text },
                    { type: 'transmission', value: v2sData?.transmissiontype?.text },

                ];

                let allv2sDone = true;
                let steveData = null;
                let mmesData = null;
                let equipmentImages = null;
                let allDataLoaded = true;

                demoVinData.tabs = [
                    ...tabLayout
                ];
                const lengthData = v2sData?.technicalData?.find(d => d.id === '14T');
                demoVinData.tabs[0].details = [
                    { key: 'numberOfDoors', value: v2sData.numberofdoors },
                    { key: 'numberOfSeats', value: v2sData.numberofseats },
                    { key: 'length', value: (`${lengthData?.value || ''} ${lengthData?.unit || ''}`) },
                    { key: 'emptyWeight', value: (v2sData.weight.vehicleMassKg !== null ? `${v2sData.weight.vehicleMassKg} kg` : '') },
                    { key: 'payload', value: (v2sData.weight.payload !== null ? `${v2sData.weight.payload} kg` : '') },
                    { key: 'maxWeight', value: (v2sData.weight.total !== null ? `${v2sData.weight.total} kg` : '') }
                ];

                if(loadedFromFileData){
                    steveData = loadedFromFileData.steveData;
                }else{
                    steveData = await loadSteveData({ vin: vinNr, apikey: (oneKeyForAll ? vimApiKey : v2sApiKey), queries: { marketId: market } });
                    saveData.steveData = steveData;
                }
                applySteveData(steveData);
                if (steveData?.isError) {
                    allv2sDone = false;
                    setLoadingStatus('specification', 'done', 'warning', [{ message: 'Steve Service Error', statusCode: steveData.statusCode }]);
                    const equipmentsTab = createEquipmentsTabFromV2S(v2sData);
                    demoVinData.tabs = [demoVinData.tabs[0], demoVinData.tabs[1], equipmentsTab];
                    steveData = null;
                }
                
                if(loadedFromFileData){
                    mmesData = loadedFromFileData.mmesData;
                }else{
                    mmesData = await loadMmesData({ vin: vinNr, apikey: (oneKeyForAll ? vimApiKey : v2sApiKey), queries: { marketId: market } });
                    saveData.mmesData = mmesData;
                }
                let mmesDetails = [[]];
                if (mmesData?.isError) {
                    const errorArray = [];
                    if (allv2sDone) {
                        errorArray[0] = 'Partially Loaded';
                    }
                    allv2sDone = false;
                    setLoadingStatus('specification', 'done', 'warning', [...errorArray, { message: 'Me Service Error', statusCode: mmesData.statusCode }]);
                    mmesDetails = [];
                    mmesData = null;
                }

                if (allv2sDone) {
                    setLoadingStatus('specification', 'done');
                }
                else {
                    allDataLoaded = false;
                }

                demoVinData.tabs.push({
                    name: 'meServices',
                    type: 'me-services',
                    details: mmesDetails
                });

                if (oneKeyForAll || vimEquipmentsApiKey) {
                    if(loadedFromFileData){
                        equipmentImages = loadedFromFileData.equipmentImages;
                    }else{
                        equipmentImages = await getVehicleEquipmentImages({ vin: vinNr, apikey: (oneKeyForAll ? vimApiKey : vimEquipmentsApiKey), queries: { marketId: market } }).then(resp => resp.data).catch(e => handleLoadError(e));
                        saveData.equipmentImages = equipmentImages;
                    }
                    if (!equipmentImages?.isError) {
                        setLoadingStatus('equipments', 'done', '')
                    }
                    else {
                        allDataLoaded = false;
                        setLoadingStatus('equipments', 'done', 'error', [equipmentImages])
                        equipmentImages = null;
                    }
                }
                else {
                    allDataLoaded = false;
                    setLoadingStatus('equipments', 'done', 'error');
                }

                if (oneKeyForAll || vvsApiKey) {
                    let videoData = null;
                    if(loadedFromFileData){
                        videoData = loadedFromFileData.vvidData;
                    }
                    else{
                        videoData = await loadVideoData({ vin: vinNr, apikey: (oneKeyForAll ? vimApiKey : vvsApiKey), queries: { locale: market } });
                        saveData.vvidData = videoData;
                    }
                    if (!videoData?.isError) {
                        setLoadingStatus('video', 'done', '');
                        demoVinData.videoDetails = videoData;
                    }
                    else {
                        allDataLoaded = false;
                        setLoadingStatus('video', 'done', 'error', [videoData])
                    }
                }
                else {
                    allDataLoaded = false;
                    setLoadingStatus('video', 'done', 'error');
                }


                if (allDataLoaded) {
                    setLoadStatus(t('vinDemoPage.userMessage.status.successfullyLoaded'));
                }
                else {
                    setLoadStatus(t('vinDemoPage.userMessage.status.partiallyLoaded'));
                }

                const id = `details-${Date.now()}`;

                setVehicleDetailsId(id);

                const vehicleDetails = {
                    vehicleId: vinNr,
                    vehicleData: { demoVinData, vehicleCard, mmesData, steveData, equipmentImages }
                };

                try {
                    localStorage.setItem(id, JSON.stringify(vehicleDetails));
                    console.log('Vehicle Details saved to local storage ' + id);
                }
                catch (e) {
                    // Delete oldest vehicleDetails data if storage is full
                    Object.keys(localStorage).some(key => {
                        if (key.includes('details-')) {
                            localStorage.removeItem(key);
                            return true;
                        }
                        return false;
                    });
                }
            }
            else {
                setLoadStatus(t('vinDemoPage.userMessage.status.couldntLoadImage'));
                setLoadingStatus('images', '', 'error');
            }
        }

        setLoading(false);
        setDataToSave(saveData);
        
    }


    const handleLoadFromZipButton = () => {
        // Load a zip file containing all images in imagesDay in a subfolder named imagesDay, v2sData in a file named specVehicleData.json, equipentsData in a file named equipentsData.json, vvidData in a file named videoData.json
        // Load the zip file to the user's computer
        
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.zip';
        input.onchange = async (e) => {
            const file = e.target.files[0];
            if (!file) {
                return;
            }
            const zip = new JSZip();
            const zipFile = await zip.loadAsync(file);
            const imagesDayData = await zipFile.file('imagesDay.json')?.async('text')?.then(JSON.parse);
            const v2sData = await zipFile.file('specVehicleData.json')?.async('text')?.then(JSON.parse);
            const equipentsData = await zipFile.file('equipentsData.json')?.async('text')?.then(JSON.parse);
            const vvidData = await zipFile.file('videoData.json')?.async('text')?.then(JSON.parse);
            const mmesData = await zipFile.file('mmesData.json')?.async('text')?.then(JSON.parse);
            const steveData = await zipFile.file('specEquipmentsData.json')?.async('text')?.then(JSON.parse);
            const equipmentImages = await zipFile.file('equipmentImages.json')?.async('text')?.then(JSON.parse);
            const metadata = await zipFile.file('metadata.json')?.async('text')?.then(JSON.parse);

            // log all data
            console.log(imagesDayData);
            console.log(v2sData);
            console.log(equipentsData);
            console.log(vvidData);
            console.log(mmesData);
            console.log(steveData);
            console.log(equipmentImages);

            let loadedVin = metadata?.vin;

            if(!loadedVin || !imagesDayData || !v2sData ){
                createNotification(t('vinDemoPage.notifications.invalidZipFile'), true);
                return;
            }



            setVin(loadedVin);

            let loadedFromFileData = new ApiReturnData();
            loadedFromFileData.v2sData = v2sData;
            loadedFromFileData.equipentsData = equipentsData;
            loadedFromFileData.vvidData = vvidData;
            loadedFromFileData.mmesData = mmesData;
            loadedFromFileData.steveData = steveData;
            loadedFromFileData.equipmentImages = equipmentImages;
            loadedFromFileData.imagesDayData = imagesDayData;
            loadedFromFileData.vin = loadedVin;

            console.log('Loaded VIN: ' + loadedVin);
            handleLoadButton(loadedFromFileData);
            
            // remove input element
            input.remove();
        };
        input.click();
    }

    const handleSaveButton = (dataToSave) => {
        // Create a zip file containing all images in imagesDay in a subfolder named imagesDay, v2sData in a file named specVehicleData.json, equipentsData in a file named equipentsData.json, vvidData in a file named videoData.json
        // Save the zip file to the user's computer

        // Create a zip file
        const zip = new JSZip();
        // const imagesDayFolder = zip.folder('imagesDay');
        // console.log(imagesDay);
        // imagesDay.forEach((image, key) => {
        //     const { url, imageBlob } = image;
        //     imagesDayFolder.file(`${key}.jpg`, imageBlob);
        // });
        if(dataToSave.imagesDayData){
            zip.file('imagesDay.json', JSON.stringify(dataToSave.imagesDayData));
        }
        if (dataToSave.v2sData) {
            zip.file('specVehicleData.json', JSON.stringify(dataToSave.v2sData));
        }
        if (dataToSave.equipentsData) {
            zip.file('equipentsData.json', JSON.stringify(dataToSave.equipentsData));
        }
        if (dataToSave.vvidData) {
            zip.file('videoData.json', JSON.stringify(dataToSave.vvidData));
        }
        if (dataToSave.mmesData) {
            zip.file('mmesData.json', JSON.stringify(dataToSave.mmesData));
        }
        if (dataToSave.steveData) {
            zip.file('specEquipmentsData.json', JSON.stringify(dataToSave.steveData));
        }
        if (dataToSave.equipmentImages) {
            zip.file('equipmentImages.json', JSON.stringify(dataToSave.equipmentImages));
        }

        var metadata = {vin: dataToSave.vin};

        zip.file('metadata.json', JSON.stringify(metadata));


        zip.generateAsync({ type: 'blob' })
            .then((content) => {
                // Save the zip file to the user's computer
                saveAs(content, vin + '.zip');
            });

    }

    const handleShowButton = () => {
        if (vehicleDetailsId === null) {
            createNotification(t('vinDemoPage.userMessage.status.loadDataToSeeVehicle'), true, 10000);
        }
        else {
            window.open(`vehicle/${vehicleDetailsId}`, '_blank');
        }
    }

    return (
        <WbCard className="demo-vin__container">
            <WbGrid fullWidth className="rd-demo-vin-heading">
                <WbGridRow>
                    <WbGridCol mq5={12} mq4={12} mq1={12} className="rd-demo-vin-heading__text">
                        <WbHeading size="l">{t('vinDemoPage.header.title')}</WbHeading>
                        <WbText size="m">
                            {t('vinDemoPage.header.' + (loadFromFile ? 'contentForOpenFromFile' : 'content'))}
                        </WbText>
                    </WbGridCol>
                </WbGridRow>
            </WbGrid>

            <WbGrid fullWidth className="rd-demo-vin-input">
                <WbGridRow className='rd-demo-vin-input__container'>
                    <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label' data-testid="load-from-file-toggle">
                        <WbText>{t('vinDemoPage.input.loadFromFile')}</WbText>
                    </WbGridCol>
                    <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} className='rd-demo-vin-input__label'>
                        <WbToggle disabled={loading ? 'true' : undefined} onWbchange={e => { setLoadFromFile(e.detail.checked) }} checked={loadFromFile} data-testid="one-key-toggle"/>
                    </WbGridCol>
                </WbGridRow>
                { !loadFromFile &&
                <>
                <WbGridRow className='rd-demo-vin-input__container'>
                    <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label'>
                        <WbText>{t('vinDemoPage.input.vinLabel')}</WbText>
                    </WbGridCol>
                    <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} >
                        <WbInput onWbinput={(e) => setVin(e.detail.value)} value={vin}>
                            <WbText slot='label' size='s'>{t('vinDemoPage.input.vinLabel')}</WbText>
                        </WbInput>
                    </WbGridCol>
                </WbGridRow>
                <WbGridRow className='rd-demo-vin-input__container'>
                    <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label'>
                        <WbText>{t('vinDemoPage.input.selectMarket')}</WbText>
                    </WbGridCol>
                    <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} className='rd-demo-vin-input__label'>
                        <WbSelect compact>
                            <select name='market' className='rd-demo-market-select' value={market} onChange={e => setMarket(e.target.value)}>
                                {
                                    marketList.map(m => <option key={m.market_code} value={m.market_code}>{m.market_name}</option>)
                                }
                            </select>
                        </WbSelect>
                    </WbGridCol>
                </WbGridRow>
                <WbGridRow className='rd-demo-vin-input__container'>
                    <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label' data-testid="one-key-input">
                        <WbText>{t('vinDemoPage.input.oneKeyForAll')}</WbText>
                    </WbGridCol>
                    <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} className='rd-demo-vin-input__label'>
                        <WbToggle onWbchange={e => { setOneKeyForAll(e.detail.checked) }} checked={oneKeyForAll} data-testid="one-key-toggle"/>
                    </WbGridCol>
                </WbGridRow>
                
                <WbGridRow className='rd-demo-vin-input__container'>
                    <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label'>
                        <WbText>Vehicle Images<sup style={{ color: 'red' }}>*</sup></WbText>
                    </WbGridCol>
                    <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} >
                        <WbInput onWbinput={(e) => setVimApiKey(e.detail.value)} value={vimApiKey} type="password" special-action-label="Toggle password visibility">
                            <WbText slot='label' size='s'>{t('vinDemoPage.input.apikeyLabel')}</WbText>
                        </WbInput>
                    </WbGridCol>
                </WbGridRow>
                
                {
                    !oneKeyForAll &&
                    <>
                        <WbGridRow className='rd-demo-vin-input__container'>
                            <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label'>
                                <WbText>Vehicle Images Equipments</WbText>
                            </WbGridCol>
                            <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} >
                                <WbInput onWbinput={(e) => setVimEquipmentsApiKey(e.detail.value)} value={vimEquipmentsApiKey} type="password" special-action-label="Toggle password visibility">
                                    <WbText slot='label' size='s'>{t('vinDemoPage.input.apikeyLabel')}</WbText>
                                </WbInput>
                            </WbGridCol>
                        </WbGridRow>
                        <WbGridRow className='rd-demo-vin-input__container'>
                            <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label'>
                                <WbText>Vehicle Videos</WbText>
                            </WbGridCol>
                            <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} >
                                <WbInput onWbinput={(e) => setVvsApiKey(e.detail.value)} value={vvsApiKey} type="password" special-action-label="Toggle password visibility">
                                    <WbText slot='label' size='s'>{t('vinDemoPage.input.apikeyLabel')}</WbText>
                                </WbInput>
                            </WbGridCol>
                        </WbGridRow>
                        <WbGridRow className='rd-demo-vin-input__container'>
                            <WbGridCol mq5={3} mq4={12} mq1={12} startColMq5={mq5Start} className='rd-demo-vin-input__label'>
                                <WbText>Vehicle Specification<sup style={{ color: 'red' }}>*</sup></WbText>
                            </WbGridCol>
                            <WbGridCol mq5={mq5Columns} mq4={12} mq1={12} >
                                <WbInput onWbinput={(e) => setV2sApiKey(e.detail.value)} value={v2sApiKey} type="password" special-action-label="Toggle password visibility">
                                    <WbText slot='label' size='s'>{t('vinDemoPage.input.apikeyLabel')}</WbText>
                                </WbInput>
                            </WbGridCol>
                        </WbGridRow>
                    </>
                }
                </>
                }
                <WbGridRow>
                    <WbGridCol mq5={2} mq4={12} mq1={12} startColMq5={7} >
                        <WbButton disabled={loading ? 'true' : undefined} variant="secondary" className='rd-demo-vin-input__button' onClick={() => loadFromFile ? handleLoadFromZipButton() : handleLoadButton() } >{t('vinDemoPage.input.loadButton')}{loading && <WbSpinner style={{ width: '16px', height: '16px' }} />}</WbButton>
                    </WbGridCol>
                    <WbGridCol mq5={2} mq4={12} mq1={12} startColMq5={9} >
                        <WbButton disabled={vehicleDetailsId === null ? 'true' : undefined} variant="secondary" className='rd-demo-vin-input__button' onClick={() => handleShowButton()} >{t('vinDemoPage.input.showVehicleButton')}</WbButton>
                    </WbGridCol>

                </WbGridRow>
                {
                    showSaveButton && vehicleDetailsId &&
                        <WbGridRow style={{ paddingTop: '5px' }}>
                            <WbGridCol mq5={2} mq4={12} mq1={12} startColMq5={9}>
                                <WbButton disabled={vehicleDetailsId === null ? 'true' : undefined} variant="secondary" className='rd-demo-vin-input__button' onClick={() => handleSaveButton(dataToSave)} >{t('vinDemoPage.input.saveVehicleButton')}</WbButton>
                            </WbGridCol>
                            
                        </WbGridRow>
                }
                {
                    showStatus &&
                    (
                        <>
                            <WbGridRow style={{ paddingTop: '30px' }}>
                                <WbGridCol mq5={10} mq4={12} startColMq5={2}>
                                    <WbStepper preventInteraction>
                                        {
                                            loadingSteps.map(e => (
                                                <WbStep key={e.name} icon={e.icon} aria-label={e.name} status={e.status} variant={e.variant}>{e.name}
                                                    {
                                                        e.errors.length > 0 &&
                                                        e.errors.map((err, i) => (
                                                            <WbSelectionContext key={i} slot="selection-context">
                                                                {
                                                                    typeof err === 'string' ?
                                                                        (<WbSelectionContextItem size="l" aria-hidden="true" ellipsis>{err}</WbSelectionContextItem>) :
                                                                        (
                                                                            <WbSelectionContextItem size="l" aria-hidden="true" ellipsis>
                                                                                {err.message}
                                                                                <span slot="label">
                                                                                    {
                                                                                        `(RC=${err.statusCode})`
                                                                                    }
                                                                                </span>
                                                                            </WbSelectionContextItem>
                                                                        )
                                                                }
                                                            </WbSelectionContext>
                                                        ))
                                                    }
                                                </WbStep>
                                            ))
                                        }
                                    </WbStepper>
                                </WbGridCol>
                            </WbGridRow>
                            <WbGridRow>
                                <WbGridCol mq1={12} mq5={12} startColMq5={2}>
                                    <WbText size='s' style={{ marginTop: '30px', color: 'var(--wb-grey-65)' }}>{loadStatus}</WbText>
                                </WbGridCol>
                            </WbGridRow>
                        </>
                    )
                }
            </WbGrid>
        </WbCard>

    );
}

export default VinInput;
